var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notify-box" }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.unreadCount > 0,
          expression: "unreadCount > 0",
        },
      ],
      staticClass: "red-dot",
    }),
    _vm._v(" "),
    _c("img", {
      staticClass: "notify-img",
      attrs: {
        src: require("./img/notify.png"),
        "data-upload": "GoCheckMessageBtn",
      },
      on: { click: _vm.onCheckMessage },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }