/*
 * @Author: zhaoyang
 * @Date: 2023-05-13 12:10:52
 * @Last Modified by: bowang
 * @Last Modified time: 2023-06-05 17:28:57
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/user',
    getAreaDataList: customGet('getAreaDataList'),
    generateCaptcha: customPost('generateCaptcha'),
    getCaptchaImage: customGet({ url: 'getCaptchaImage/:captchaKey', link: true }),
    fetch: customGet('/api/v2/user/fetch'),
    acceptPromotionPush: customPost('acceptPromotionPush'),
    getUsertoken: customGet('getUsertoken')
};
export default assign(api);
