/*
 * @Author: Quxiaoya
 * @Date: 2019-06-25 16:31:51
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-12 16:32:27
 */

import {getVersionCode, isIOS} from 'ssr-common/util/webview/ua';

export default context => ({
    data() {
        let ua;
        if (context) {
            ua = context.userAgent;
        } else if (this.$isServer && this.$ssrContext) {
            ua = this.$ssrContext.userAgent;
        } else if (!this.$isServer) {
            ua = window.navigator.userAgent;
        }

        const versionCode = getVersionCode(ua);

        return {ua, versionCode, isIOS: isIOS(ua)};
    }
});
