<!-- @Author: zhaoyang -->
<!-- @Date: 2023-05-10 18:26:05 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-05-10 18:26:05 -->

<template>
    <div class="webview-loading-circle">
        <div class="loading-content">
            <svg
                class="circular"
                viewBox="0 0 50 50"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="15"
                    fill="none"
                    :stroke="loadingColor"
                    stroke-width="5%"
                />
            </svg>
        </div>
    </div>
</template>

<script type="text/babel">
export default {
    name: 'WebviewLoadingCircle',

    props: {
        loadingColor: {
            type: String,
            default: '#177f5c'
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .webview-loading-circle {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3000;

        .loading-content {
            position: absolute;
            top: 40%;
            left: 46%;
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 50%;

            .circular {
                position: absolute;
                left: 0;
                top: 0;
                animation: rotate 2s linear infinite;

                circle {
                    animation: circle-dash 2s ease-in-out infinite;
                }
            }
        }

        @keyframes circle-dash {
            0% {
                stroke-dasharray: 1, 125;
                stroke-dashoffset: 0;
            }

            50% {
                stroke-dasharray: 125, 125;
                stroke-dashoffset: -25px;
            }

            100% {
                stroke-dasharray: 125, 125;
                stroke-dashoffset: -125px;
            }
        }

        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }
    }
</style>
