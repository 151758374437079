<!--
 * @Author: zhaoyang
 * @Date: 2023-03-28 17:17:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-14 16:24:38
-->

<template>
    <button
        class="ec-btn"
        :class="{
            'free-size': freeSize,
            disable: allowDisableClick && disabled
        }"
        :style="btnStyle"
        :disabled="!allowDisableClick && disabled"
        @click="onClick"
    >
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'EcBtn',

    props: {
        allowDisableClick: {
            type: Boolean,
            default: false
        },

        text: {
            type: String,
            default: 'Kirim'
        },

        freeSize: {
            type: Boolean,
            default: false
        },

        btnStyle: {
            type: Object,
            default: () => ({})
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onClick(evt) {
            this.$emit('click', evt);
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~easycash-h5/common/style/variables";

.ec-btn {
    box-sizing: border-box;
    border: 0;
    outline: 0;
    width: 100%;
    height: 0.52rem;
    background-color: $mainGreen;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Helvetica;
    font-weight: bold;
    font-size: 18px;
    line-height: 0.52rem;
    border-radius: 8px;

    &:disabled {
        background-color: #a3acbc !important;
    }
}

.free-size {
    border-radius: 5px;
    width: unset;
    height: unset;
    padding: 0.05rem;
    font-size: 12px;
    line-height: 14px;
}

.disable {
    background-color: #a3acbc;
}
</style>
