/*
 * @Author: zhaoyang
 * @Date: 2023-05-11 12:13:49
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-11 12:18:00
 */
import { moneyFormat } from './money';
export default {
    install(Vue) {
        Vue.filter('money', moneyFormat);
    }
};
