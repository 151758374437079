var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "customer-service",
    attrs: {
      src: require("./img/customer-service.png"),
      "data-upload": "GoCustomerServiceBtn",
    },
    on: { click: _vm.onLinkCustomerService },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }