<!-- @Author: bowang  -->
<!-- @Date: 2023-05-23 19:04:42  -->
<!-- @Last Modified by:   bowang  -->
<!-- @Last Modified time: 2023-05-23 19:04:42  -->

<template>
    <img
        class="customer-service"
        src="./img/customer-service.png"
        data-upload="GoCustomerServiceBtn"
        @click="onLinkCustomerService"
    >
</template>

<script>
import {mapGetters} from 'vuex';

import generalConfig from 'easycash-h5/common/resource/general-config';

export default {
    name: 'EcCusomerService',

    data() {
        return {
            link: ''
        };
    },

    computed: {
        ...mapGetters('baseData', ['userToken'])
    },

    mounted() {
        this.getCusomerService();
    },

    methods: {
        async getCusomerService() {
            const {data: {body: {configs}}} = await generalConfig.get({params: {keys: 'app_config.customer_service_display'}});
            if (!configs.length) return;
            try {
                const {url} = JSON.parse(configs[0].configValue);
                this.link = url;
            } catch {
                // ignore
            }
        },

        onLinkCustomerService() {
            window.location.href = `${this.link}&userToken=${this.userToken || ''}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.customer-service {
    width: 0.22rem;
    height: 0.16rem;
}
</style>
