/*
 * @Author: zhaoyang
 * @Date: 2023-07-31 11:06:00
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-08-02 16:03:45
 */
export default {
    namespaced: true,
    state: () => ({
        channelName: ''
    }),
    getters: {
        channelName: state => state.channelName
    },
    mutations: {
        setChannelName: (state, channelName) => {
            state.channelName = channelName;
        }
    }
};
