/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-06-16 14:46:44
 */

import {
    authNav,
    defaultNav,
    homeNav,
    billsNav,
    meNav,
    appGuideNav
} from '../constant/nav-bar';

const routes = [
    {
        path: '/',
        redirect: {name: 'home'}
    },
    {
        path: '',
        name: 'index',
        component: () => import('easycash-h5/app/index'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('easycash-h5/app/home'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            keepAlive: true,
            showTabBar: true,
            navBar: homeNav
        }
    },
    {
        path: '/bills',
        name: 'bills',
        component: () => import('easycash-h5/app/bills'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            showTabBar: true,
            navBar: billsNav
        }
    },
    {
        path: '/auth-half',
        name: 'authHalf',
        component: () => import('easycash-h5/app/auth/half'),
        children: [
            {
                path: 'bank',
                name: 'bankHalf',
                component: () => import('easycash-h5/app/auth/bank'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'address-contacts',
                name: 'addressContactsHalf',
                component: () => import('easycash-h5/app/auth/address-contacts'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'work',
                name: 'workHalf',
                component: () => import('easycash-h5/app/auth/work'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            }
        ]
    },
    {
        path: '/auth',
        name: 'auth',
        component: () => import('easycash-h5/app/auth'),
        children: [
            {
                path: 'ktp-shoot',
                name: 'ktpShoot',
                component: () => import('easycash-h5/app/auth/ktp-shoot'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'ktp-fill',
                name: 'ktpFill',
                component: () => import('easycash-h5/app/auth/ktp-fill'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'face-live',
                name: 'faceLive',
                component: () => import('easycash-h5/app/auth/face-live'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'fineasy-face-live',
                name: 'fineasyFaceLive',
                component: () => import('easycash-h5/app/auth/fineasy-face-live'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'bank',
                name: 'bank',
                component: () => import('easycash-h5/app/auth/bank'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'address-contacts',
                name: 'addressContacts',
                component: () => import('easycash-h5/app/auth/address-contacts'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'work',
                name: 'work',
                component: () => import('easycash-h5/app/auth/work'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'additional',
                name: 'additional',
                component: () => import('easycash-h5/app/auth/additional'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: authNav
                }
            },
            {
                path: 'edu',
                name: 'edu',
                component: () => import('easycash-h5/app/edu'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    },
                    navBar: defaultNav
                }
            }
        ]
    },
    {
        path: '/go-pay',
        name: 'goPay',
        component: () => import('easycash-h5/app/go-pay'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: {
                bgColor: '#25A5D9',
                backColor: '#fff',
                titleColor: '#fff'
            }
        }
    },
    {
        path: '/webview/agreement/:key',
        name: 'agreement',
        component: () => import('easycash-h5/app/agreement'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/webview/agreement-pdf',
        name: 'agreementPdf',
        component: () => import('easycash-h5/app//webview/agreement-pdf'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/webview/static-agreement/:key',
        name: 'staticAgreementLoan',
        component: () => import('easycash-h5/app//webview/static-agreement'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/register-login',
        name: 'registerLogin',
        component: () => import('easycash-h5/app/register-login'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            keepAlive: true
        }
    },
    {
        path: '/register-login-status',
        name: 'RegisterLoginStatus',
        component: () => import('easycash-h5/app/register-login/status'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: '/register-login-half',
        name: 'registerLoginHalf',
        component: () => import('easycash-h5/app/register-login/half'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: '/protocol',
        name: 'protocol',
        component: () => import('easycash-h5/app/protocol'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('easycash-h5/app/order'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/order-detail/:id',
        name: 'orderDetail',
        component: () => import('easycash-h5/app/order/detail'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/contract-list/:id',
        name: 'contractList',
        component: () => import('easycash-h5/app/order/contract'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/repay-info',
        name: 'repayInfo',
        component: () => import('easycash-h5/app/repay-info'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/me',
        name: 'me',
        component: () => import('easycash-h5/app/me'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            showTabBar: true,
            navBar: meNav
        }
    },
    {
        path: '/help-center',
        name: 'helpCenter',
        component: () => import('easycash/app/webview/customer-service'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/message',
        name: 'message',
        component: () => import('easycash-h5/app/message-center/message'),
        meta: {
            navBar: defaultNav
        }
    },
    {
        path: '/message/:id',
        name: 'messageDetail',
        component: () => import('easycash-h5/app/message-center/message-detail'),
        meta: {
            navBar: defaultNav
        }
    },
    {
        path: '/webview/withdraw',
        name: 'withdraw',
        component: () => import('easycash-h5/app/withdraw'),
        meta: {
            titleKey: 'withdraw.navTitle',
            navBar: defaultNav
        }
    },
    {
        path: '/app-guide',
        name: 'appGuide',
        component: () => import('easycash-h5/app/app-guide'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: appGuideNav
        },
    },
    {
        path: '/app-guide-half',
        name: 'appGuideHalf',
        component: () => import('easycash-h5/app/app-guide/half'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        }
    },
    {
        path: '/delete-user/guide',
        name: 'deleteUserGuide',
        component: () => import('easycash-h5/app/delete-user/guide'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/delete-user',
        name: 'deleteUser',
        component: () => import('easycash-h5/app/delete-user'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/delete-user/reason',
        name: 'deleteUserReason',
        component: () => import('easycash-h5/app/delete-user/reason'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '/delete-user/submit',
        name: 'deleteUserSubmit',
        component: () => import('easycash-h5/app/delete-user/submit'),
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            },
            navBar: defaultNav
        }
    },
    {
        path: '*',
        component: () => import('easycash-h5/app/not-found')
    }
];

export default routes;
