/*
 * @Author: bowang
 * @Date: 2023-06-05 16:21:23
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-12-19 19:24:08
 */
import { isProd } from 'src/common/util/env';
export const isMobile = ua => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);
export const isIOS = ua => /iphone|ipad|ipod/i.test(ua);
export const isMac = ua => /Macintosh/i.test(ua);
export const getUa = () => {
    var _a, _b;
    return (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) !== null && _b !== void 0 ? _b : '';
};
export const getDownloadUrl = ua => {
    if (isIOS(ua) || isMac(ua)) {
        return 'https://itunes.apple.com/app/id1435044790?mt=8';
    }
    else {
        return 'https://play.google.com/store/apps/details?id=com.fintopia.idnEasycash.google';
    }
};
export const getGoAppUrl = ua => {
    if (!isMobile(ua)) {
        return getDownloadUrl(ua);
    }
    if (isIOS(ua)) {
        return isProd ? 'Easycash://' : 'EasycashTest://';
    }
    else {
        return 'easycash://';
    }
};
