/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-22 16:34:16
 */

import '@shared/client/util/public-path';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'ssr-common/util/report';
import '@vant/touch-emulator';
import {rejectFailedStatus} from '@yqg/http';
import {axios as http} from '@yqg/resource';

import {initSensors} from '@shared/client/util/sensors';
import {initSentry} from '@shared/client/util/sentry';
import {getUserId} from '@shared/client/util/user-token';
import {STAGE} from '@shared/common/constant/stage';

import Vue from 'ssr-common/vue';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';

import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

Vue.mixin({
    beforeRouteEnter(to, from, next) {
        const needReplace = !to.query.channelName && from.query.channelName;
        if (needReplace) {
            to.query.channelName = from.query.channelName;
        }

        next(vm => {
            if (!needReplace) return;
            const query = vm.$route.query;
            const random = Math.random().toString().slice(2);
            vm.$router.replace({query: {...query, random}});
        });
    }
});

const {router} = createClientEntry({createApp});

// sensors start
Vue.prototype.$sensors = initSensors({
    platformName: 'easycash_outside',
    country: 'indo',
    businessName: 'IDN_YQD',
    userId: getUserId(),
    useAppJsBridge: false
});
// sensors end

initSentry({
    router,
    dsn: 'https://ae1617b4cb03d3f472064d8ff00f7145@sentry.fintopia.tech/45',
    tracesSampleRate: /prod/i.test(STAGE) ? 0.05 : 1
});

if (module.hot) {
    module.hot.accept();
}
