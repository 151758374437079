/*
 * @Author: zhaoyang
 * @Date: 2022-11-28 17:52:03
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-06-25 19:21:33
 */

import {upperFirst} from 'lodash';
import {mapState} from 'vuex';

import EventUtil from 'easycash-h5/common/util/event';
import {genClickEvent} from 'easycash-h5/common/util/event-helper';
import {getBrowserInfo} from 'easycash-h5/common/util/get-browser-info';

export default {
    data() {
        return {
            browserInfo: {}
        };
    },

    provide() {
        const {uploadClickEvent} = this;

        return {uploadClickEvent};
    },

    computed: {
        ...mapState('baseData', ['brand']),

        isLoginPage() {
            return this.$route.name?.includes?.('Login');
        }
    },

    methods: {
        async uploadClickEvent(tag, elementContent) {
            const {$route, browserInfo, isLoginPage} = this;
            const {uploadQuery} = $route.meta?.business ?? {};

            const query = uploadQuery ? {...$route.query} : {};

            if (!isLoginPage) {
                const {mode} = this.$app.getEventParam('userInfo') ?? {};
                query.userMode = mode;
            }

            if (query.channelName && !query.channelName.startsWith('web_')) {
                query.channelName = `web_${query.channelName}`;
            }

            await EventUtil.uploadEvent(
                genClickEvent(
                    $route.name,
                    this.brand,
                    tag,
                    {...query, ...elementContent, ...browserInfo}
                ),
                this.$route,
                this.brand
            );
        },

        uploadEvent(evt) {
            const {brand, $route, browserInfo, isLoginPage} = this;
            if (!brand) return;
            let {target} = evt;
            let tag = target.dataset.upload;

            while (!tag && target) {
                target = target?.parentNode;
                tag = target?.dataset?.upload;
            }

            if (!tag) return;

            const {uploadQuery} = $route.meta?.business ?? {};

            const query = uploadQuery ? {...$route.query} : {};

            if (!isLoginPage) {
                const {mode} = this.$app.getEventParam('userInfo') ?? {};
                query.userMode = mode;
            }

            if (query.channelName && !query.channelName.startsWith('web_')) {
                query.channelName = `web_${query.channelName}`;
            }

            EventUtil.uploadEvent(
                {
                    tag,
                    businessEvent: `${upperFirst(brand)}_${$route.name}_${tag}`,
                    elementType: 'Button',
                    eventType: 'C',
                    elementContent: {...browserInfo, ...query}
                },
                $route,
                brand
            );
        }
    },

    mounted() {
        const {uploadEvent, ua} = this;
        this.browserInfo = getBrowserInfo(ua);
        document.body.addEventListener('click', uploadEvent);
    },

    beforeDestroy() {
        const {uploadEvent} = this;
        document.body.removeEventListener('click', uploadEvent);
    }
};
