/*
 * @Author: ruiwang
 * @Date 2023-06-01 12:33:52
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-03 18:24:53
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Home from 'src/common/resource/home';
import Loan from 'src/common/resource/loan';
function isPending(status) {
    return status === 'IN_REVIEW';
}
const hideLoading = true;
function fetchStatus(homeData) {
    return __awaiter(this, void 0, void 0, function* () {
        const { data: { body: { creditsStatus } } } = yield Loan.creditsInfo({ hideLoading });
        return creditsStatus;
    });
}
export default {
    namespaced: true,
    state: () => ({
        homeData: null,
        timer: null,
        status: '',
        signTimer: null,
        error: null
    }),
    getters: {
        homeData(state) {
            return state.homeData;
        },
        error(state) {
            return state.error;
        },
        userInfo(state) {
            var _a;
            return ((_a = state.homeData) === null || _a === void 0 ? void 0 : _a.userInfo) || {};
        },
        product(state) {
            var _a;
            return (_a = state.homeData) === null || _a === void 0 ? void 0 : _a.product;
        },
        status(state) {
            return state.status;
        },
        isPending(state) {
            return isPending(state.status);
        }
    },
    mutations: {
        setError(state, data) {
            state.error = data;
        },
        set: (state, data) => {
            state.homeData = data;
        },
        clear: state => {
            state.homeData = null;
        },
        setTimer: (state, data) => {
            state.timer = data;
        },
        clearTimer: state => {
            if (state.timer) {
                clearTimeout(state.timer);
            }
        },
        setStatus: (state, data) => {
            state.status = data;
        },
        setSignTimer: (state, data) => {
            state.signTimer = data;
        },
        clearSignTimer: state => {
            if (state.signTimer) {
                clearTimeout(state.signTimer);
            }
        }
    },
    actions: {
        fetchHome: {
            root: true,
            handler: ({ commit, dispatch, rootGetters }, userFetchPromsie) => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                try {
                    const { data: { body } } = yield Home.get();
                    commit('set', body);
                    const { userInfo: { displayStatus } } = body;
                    // userFetchPromsie是为了保证并行请求v2/user/fetch和/api/v5/cashloan/home，同时不影响已有执行逻辑
                    if (Object.prototype.toString.call(userFetchPromsie) === '[object Promise]') {
                        yield userFetchPromsie;
                    }
                    const { 'baseData/isLogin': isLogin } = rootGetters;
                    if (isLogin && displayStatus === 'REVIEW') {
                        dispatch('refreshStatus');
                    }
                    commit('setError', null);
                }
                catch (error) {
                    commit('setError', (_b = (_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.status) !== null && _b !== void 0 ? _b : {});
                }
            }),
        },
        refreshStatus({ commit, dispatch, state, rootGetters }) {
            return __awaiter(this, void 0, void 0, function* () {
                commit('clearTimer');
                const { 'baseData/isLogin': isLogin } = rootGetters;
                if (!isLogin) {
                    return;
                }
                const status = yield fetchStatus(state.homeData);
                if (!isPending(status)) {
                    dispatch('fetchHome', null, { root: true });
                    return;
                }
                const timer = setTimeout(() => {
                    dispatch('refreshStatus');
                }, 10 * 1e3);
                commit('setTimer', timer);
            });
        },
        refreshSign({ commit, dispatch, state, rootGetters }) {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function* () {
                commit('clearSignTimer');
                const { 'baseData/isLogin': isLogin } = rootGetters;
                if (!isLogin) {
                    return;
                }
                const { businessId, businessType, checkTypeGroup } = ((_b = (_a = state.homeData) === null || _a === void 0 ? void 0 : _a.userInfo) === null || _b === void 0 ? void 0 : _b.bizCheckInfo) || {};
                const cond = { businessId, businessType, checkTypeGroup };
                const { data: { body: { checkGroupStatus } } } = yield Home.result(cond, { hideLoading });
                if (checkGroupStatus === 'SUCCESS') {
                    dispatch('fetchHome', null, { root: true });
                    return;
                }
                const timer = setTimeout(() => {
                    dispatch('refreshSign');
                }, 10 * 1e3);
                commit('setSignTimer', timer);
            });
        },
        clearTimer({ commit }) {
            commit('clearTimer');
        }
    }
};
