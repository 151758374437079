var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-tabbar",
    {
      on: { change: _vm.go },
      model: {
        value: _vm.active,
        callback: function ($$v) {
          _vm.active = $$v
        },
        expression: "active",
      },
    },
    [
      _c("van-tabbar-item", {
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function (ref) {
              var active = ref.active
              return [
                active
                  ? _c("img", {
                      attrs: { src: require("./img/home-active.svg") },
                    })
                  : _c("img", { attrs: { src: require("./img/home.svg") } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.getI18n("home")))]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("van-tabbar-item", {
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function (ref) {
              var active = ref.active
              return [
                _c("div", { staticClass: "bills-wrap" }, [
                  active
                    ? _c("img", {
                        attrs: { src: require("./img/bills-active.svg") },
                      })
                    : _c("img", { attrs: { src: require("./img/bills.png") } }),
                  _vm._v(" "),
                  _vm.isLogin &&
                  _vm.billsTipData &&
                  _vm.billsTipData.repayingInstalmentCount > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "bills-tag",
                          class: [
                            _vm.billsTipData.overdue ? "bg-red" : "bg-yellow",
                          ],
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.billsTipData.repayingInstalmentCount) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.getI18n("bills")))]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("van-tabbar-item", {
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function (ref) {
              var active = ref.active
              return [
                active
                  ? _c("img", {
                      attrs: { src: require("./img/me-active.svg") },
                    })
                  : _c("img", { attrs: { src: require("./img/me.svg") } }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.getI18n("me")))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }