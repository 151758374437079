<!--
 * @Author: zhaoyang
 * @Date: 2023-05-17 22:40:25
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-17 23:21:36
-->

<template>
    <svg
        :width="size"
        :height="size"
        :viewBox="viewBox"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.995 4.99997H3.40501L6.70501 1.71268C6.89332 1.52453 6.9991 1.26935 6.9991 1.00326C6.9991 0.737183 6.89332 0.481998 6.70501 0.29385C6.51671 0.105701 6.26132 0 5.99501 0C5.72871 0 5.47332 0.105701 5.28501 0.29385L0.285014 5.28973C0.193973 5.38476 0.122608 5.49681 0.0750135 5.61946C-0.0250045 5.86272 -0.0250045 6.13557 0.0750135 6.37883C0.122608 6.50149 0.193973 6.61354 0.285014 6.70856L5.28501 11.7044C5.37798 11.7981 5.48858 11.8724 5.61044 11.9232C5.7323 11.9739 5.863 12 5.99501 12C6.12703 12 6.25773 11.9739 6.37959 11.9232C6.50145 11.8724 6.61205 11.7981 6.70501 11.7044C6.79874 11.6116 6.87314 11.501 6.9239 11.3793C6.97467 11.2575 7.00081 11.1269 7.00081 10.995C7.00081 10.8631 6.97467 10.7325 6.9239 10.6108C6.87314 10.489 6.79874 10.3785 6.70501 10.2856L3.40501 6.99832H10.995C11.2602 6.99832 11.5146 6.89305 11.7021 6.70567C11.8897 6.51829 11.995 6.26415 11.995 5.99915C11.995 5.73415 11.8897 5.48001 11.7021 5.29262C11.5146 5.10524 11.2602 4.99997 10.995 4.99997Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'Back',

    props: {
        color: {
            type: String,
            default: '#fff'
        },

        size: {
            type: Number,
            default: 12
        }
    },

    computed: {
        viewBox() {
            const {size} = this;

            return `0 0 ${size} ${size}`;
        }
    }
};
</script>
