/*
 * @Author: zhaoyang
 * @Date: 2023-06-14 10:29:05
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-06-14 17:25:21
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/cashloan',
    instalmentList: customGet('instalmentList'),
    billingOrderCountTip: customGet('billingOrderCountTip'),
    completed: customPost('assertNoInstalmentCompleted')
};
export default assign(api);
