/*
 * @Author: zhaoyang
 * @Date: 2023-04-20 18:04:14
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-26 09:46:40
 */
import { upperFirst } from 'lodash';
import { BRAND } from 'easycash-h5/common/constant/config';
export const genClickEvent = (routeName, brand, tag, elementContent) => ({
    tag,
    businessEvent: `${upperFirst(brand)}_${routeName}_${tag}`,
    elementType: 'Button',
    eventType: 'C',
    elementContent
});
export const genEcH5ClickEvent = (routeName, tag, elementContent) => genClickEvent(routeName, BRAND, tag, elementContent);
