/*
 * @Author: zhaoyang
 * @Date: 2023-05-10 22:47:39
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-04 13:45:34
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Vue from 'vue';
import yqgCookie from 'ssr-common/util/yqg-cookie';
import { BRAND } from 'easycash-h5/common/constant/config';
import RegisterLoginApi from 'easycash-h5/common/resource/register-login';
import UserApi from 'easycash-h5/common/resource/user';
import Loan from 'src/common/resource/loan';
const SET_USER_INFO = 'SET_USER_INFO';
const SET_BANK = 'SET_BANK';
const SET_USER_TOKEN = 'SET_USER_TOKEN';
export default {
    namespaced: true,
    state: () => ({
        brand: BRAND,
        userInfo: null,
        bankList: null,
        userToken: null
    }),
    getters: {
        userInfo: state => state.userInfo,
        isLogin: state => !!state.userInfo,
        bank: state => { var _a; return (_a = state.bankList) === null || _a === void 0 ? void 0 : _a[0]; },
        userToken: state => state.userToken
    },
    mutations: {
        [SET_USER_INFO](state, payload) {
            Vue.set(state, 'userInfo', payload);
        },
        [SET_BANK](state, payload) {
            Vue.set(state, 'bankList', payload);
        },
        [SET_USER_TOKEN](state, payload) {
            Vue.set(state, 'userToken', payload);
        }
    },
    actions: {
        getUserInfo({ commit, dispatch }) {
            return __awaiter(this, void 0, void 0, function* () {
                const userFetchPromsie = RegisterLoginApi.fetch().then(res => {
                    const { data: { body: userInfo } } = res;
                    commit(SET_USER_INFO, userInfo);
                });
                dispatch('fetchHome', userFetchPromsie, { root: true });
                Loan.listPaymentCredentials().then(({ data: { body: { paymentCredentials } } }) => {
                    commit(SET_BANK, paymentCredentials);
                });
                UserApi.getUsertoken().then(({ data: { body } }) => {
                    commit(SET_USER_TOKEN, body);
                });
                yield userFetchPromsie;
                localStorage.setItem('loginStatus', '1');
            });
        },
        fetchBank({ commit, getters: { isLogin } }) {
            return __awaiter(this, void 0, void 0, function* () {
                if (!isLogin)
                    return;
                const { data: { body: { paymentCredentials } } } = yield Loan.listPaymentCredentials();
                commit(SET_BANK, paymentCredentials);
            });
        },
        logout({ commit, dispatch }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield RegisterLoginApi.logout();
                yqgCookie.clearUserToken();
                commit(SET_USER_INFO, null);
                commit(SET_USER_TOKEN, null);
                dispatch('fetchHome', null, { root: true });
                localStorage.removeItem('loginStatus');
            });
        },
        clearUserInfo({ commit }) {
            commit(SET_USER_INFO, null);
            commit(SET_BANK, null);
            localStorage.removeItem('loginStatus');
        }
    }
};
