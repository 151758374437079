<!--
 * @Author: zhaoyang
 * @Date: 2023-05-18 09:11:12
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-07-19 15:29:22
-->

<template>
    <van-nav-bar
        :border="false"
        :style="navBarStyle"
        v-bind="$attrs"
    >
        <template #left>
            <div class="left-box">
                <back
                    v-if="needBack"
                    :color="backColor"
                    @click.native="backClick"
                />
                <template v-if="isShowBanner">
                    <img
                        class="left-banner"
                        src="./img/left-banner.png"
                    >
                    <span class="black-text">TKB90=100%</span>
                </template>
            </div>
        </template>
        <template #title>
            <div
                class="van-ellipsis"
                :style="titleStyle"
            >
                {{ isHideTitle ? '' : title }}
            </div>
        </template>
        <template #right>
            <div class="right-box">
                <ec-customer-service
                    v-if="isShowCustomerService"
                    class="customer-service"
                />
                <ec-notify
                    v-if="isShouNotify"
                    class="notify-box"
                />
                <img
                    v-if="isShowOrder"
                    class="order-icon"
                    src="./img/order.svg"
                    @click="goOrder"
                >
                <div
                    v-if="!$options.isProd"
                    class="switch-language"
                    @click="switchLanguage"
                >
                    {{ displayLocal }}
                </div>
            </div>
        </template>
    </van-nav-bar>
</template>

<script>
import {mapGetters} from 'vuex';

import Back from 'easycash-h5/common/component/icon/back';

import {isProd} from 'src/common/util/env';

import EcCustomerService from './ec-customer-service';
import EcNotify from './ec-notify';

export default {
    name: 'EcNavBar',

    isProd,

    components: {
        Back,
        EcCustomerService,
        EcNotify
    },

    props: {
        navBarOptions: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            pageTitle: null
        };
    },

    computed: {
        ...mapGetters('baseData', ['isLogin']),

        title() {
            if (this.$isServer) return this.$ssrContext.title;

            return this.pageTitle ?? document.title;
        },

        needBack() {
            const {query: {noReturn}} = this.$route;
            if (noReturn === 'true') return false;

            return this.navBarOptions?.needBack ?? true;
        },

        navBarStyle() {
            const {bgColor} = this.navBarOptions ?? {};

            return {
                backgroundColor: bgColor ?? '#f4f4f4'
            };
        },

        backColor() {
            return this.navBarOptions?.backColor ?? '#000';
        },

        titleStyle() {
            const {titleColor} = this.navBarOptions ?? {};

            return {
                fontSize: '14px',
                color: titleColor ?? '#000'
            };
        },

        isShowCustomerService() {
            return this.navBarOptions?.right?.cs;
        },

        isShouNotify() {
            return this.navBarOptions?.right?.notify && this.isLogin;
        },

        isShowOrder() {
            return this.navBarOptions?.right?.order;
        },

        isShowBanner() {
            return this.navBarOptions?.showBanner;
        },

        isHideTitle() {
            return this.navBarOptions?.hideTitle;
        },

        displayLocal() {
            return this.$i18n.locale === 'id' ? 'id' : '中';
        }
    },

    mounted() {
        this.observeTitle();
    },

    methods: {
        goOrder() {
            this.$router.push({name: 'order'});
        },

        switchLanguage() {
            const locale = this.$i18n.locale;
            this.$i18n.locale = locale === 'id' ? 'zh' : 'id';
        },

        observeTitle() {
            const target = document.querySelector('title');
            const observer = new MutationObserver(() => {
                this.pageTitle = document.title;
            });
            observer.observe(target, {
                characterData: true,
                childList: true,
                subtree: true
            });
        },

        backClick() {
            const {query: {backHome, backMe}} = this.$route;
            if (backHome) {
                this.$router.replace({name: 'home'});

                return;
            } else if (backMe) {
                this.$router.replace({name: 'me'});

                return;
            }

            this.$router.back();
        },

        onCheckMessage() {
            this.$router.push({name: 'message'});
        }
    }
};
</script>

<style lang="scss" scoped>
.left-box {
    display: flex;

    .left-banner {
        width: 0.69rem;
        height: 0.18rem;
    }

    .black-text {
        margin-left: 0.05rem;
        color: #000;
        font-weight: 700;
        font-size: 14px;
    }
}

.right-box {
    display: flex;
    height: 100%;
    align-items: center;

    .notify-box {
        margin-left: 0.08rem;
        position: relative;
    }

    .order-icon {
        margin-left: 0.1rem;
        width: 0.15rem;
        height: 0.17rem;
    }
}

.switch-language {
    margin-left: 0.1rem;
    font-size: 14px;
    color: #999;
}
</style>
