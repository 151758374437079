/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-06-06 16:10:13
 */
/* global RUN_PORT */
export const rootTitle = 'Easycash - Kredit Dana Online';
export const BRAND = 'easycash-h5';
export const gtagAdsId = 'AW-830226380';
export const initGtagAds = vm => {
    vm.$gtag.config({
        id: gtagAdsId
    });
};
export const EmailReg = /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}/;
export const getHost = () => (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;
