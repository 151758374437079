/*
 * @Author: zhaoyang
 * @Date: 2023-05-17 22:28:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-12-19 19:17:04
 */
export const defaultNav = {};
export const authNav = {
    backColor: '#fff',
    titleColor: '#fff',
    bgColor: '#007715',
    right: {
        cs: true
    }
};
export const homeNav = {
    showBanner: true,
    needBack: false,
    hideTitle: true,
    right: {
        cs: true,
        notify: true
    }
};
export const billsNav = {
    needBack: false,
    right: {
        cs: true,
        order: true
    }
};
export const meNav = {
    needBack: false,
    hideTitle: true,
    right: {
        cs: true,
        notify: true
    }
};
export const appGuideNav = {
    hideTitle: true,
};
