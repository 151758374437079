/*
 * @Author: ruiwang
 * @Date: 2024-01-30 17:18:45
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-01-31 14:53:01
 */
/* global
  __TONGDUN_PARTNER_CODE__
*/
export const TONGDUN_PARTNER_CODE = __TONGDUN_PARTNER_CODE__;
export const TONDUN_APP_NAME = 'Eashcasy_web';
export const TONDUN_BASE_URL = 'https://static.tongdun.net/idn/fm.js';
