/*
 * @Author: ruiwang
 * @Date: 2024-01-31 17:13:47
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-01-31 17:18:08
 */
export default {
    methods: {
        saveFinseasy(key, value) {
            try {
                sessionStorage.setItem(key, JSON.stringify(value));
            }
            catch (e) {
                // ignore saveFinseasy error
            }
        },
        getFinseasy(key) {
            try {
                return JSON.parse(sessionStorage.getItem(key));
            }
            catch (e) {
                // ignore getFinseasy error
            }
        },
        removeFinseasy(key) {
            sessionStorage.removeItem(key);
        }
    }
};
