/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-10 18:26:05
 */

export default {

    install() {
    }

};
