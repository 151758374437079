var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ec-btn",
      class: {
        "free-size": _vm.freeSize,
        disable: _vm.allowDisableClick && _vm.disabled,
      },
      style: _vm.btnStyle,
      attrs: { disabled: !_vm.allowDisableClick && _vm.disabled },
      on: { click: _vm.onClick },
    },
    [_vm._v("\n    " + _vm._s(_vm.text) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }