/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-05-10 18:26:05
 */

/* global __GTAG_ID__ */

import {upperFirst} from 'lodash';
import VueGtag from 'vue-gtag';

import {axios as http} from '@yqg/resource';

import {isServer} from 'ssr-common/constant/config';
import {getLocalStorage, removeLocalStorage} from 'ssr-common/util/local-storage';
import Vue from 'ssr-common/vue';

import {BRAND} from 'easycash-h5/common/constant/config';
import EventUtil from 'easycash-h5/common/util/event';
import {getBrowserInfo} from 'easycash-h5/common/util/get-browser-info';
import {getUa} from 'easycash-h5/common/util/ua';

import App from './app/app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

export const createApp = context => {
    const {common} = http.defaults.headers;

    const i18n = createI18n(context);
    const router = createRouter();
    const store = createStore();

    if (!isServer()) {
        // 从 context 中获取数据，注入到 store 中
        const {businessData} = context; // businessData 是从配置中心获取的业务数据
        window.__BUSINESS_DATA__ = businessData; // eslint-disable-line
    }

    router.afterEach((to, from) => {
        if (to.query?.channelName) {
            if (to.name?.includes?.('Half')) {
                common['CHANNEL'] = `${to.query.channelName}_half`;
            } else {
                common['CHANNEL'] = to.query.channelName;
            }
        } else {
            if (to.name?.includes?.('Half')) {
                common['CHANNEL'] = '_half';
            }
        }

        if (to.name === from.name) {
            return;
        }

        const {query, meta = {}, name} = to;
        const {business = {}} = meta;
        const {enterEvent, uploadQuery, buriedPoint} = business;

        if (enterEvent && !isServer() && !buriedPoint) {
            const {elementContent = {}} = enterEvent;
            const event = {
                ...enterEvent,
                elementContent: uploadQuery ? {
                    ...query, ...elementContent
                } : {...elementContent}
            };
            EventUtil.uploadEvent(event, to, BRAND);
        }

        if (buriedPoint && !isServer()) {
            const browserInfo = getBrowserInfo(getUa());
            const dealQuery = {...query};

            if (dealQuery.channelName && !dealQuery.channelName.startsWith('web_')) {
                dealQuery.channelName = `web_${dealQuery.channelName}`;
            }

            const isLoginPage = to.name?.includes?.('Login');

            if (!isLoginPage) {
                const userInfo = getLocalStorage('userInfo');
                dealQuery.userMode = userInfo?.mode;
            } else {
                removeLocalStorage('userInfo');
            }

            const event = {
                tag: 'PageEnter',
                businessEvent: `${upperFirst(BRAND)}_${name}_PageEnter`,
                elementType: 'Button',
                eventType: 'P',
                elementContent: uploadQuery ? {...dealQuery, ...browserInfo} : browserInfo
            };
            EventUtil.uploadEvent(event, to, BRAND);
        }
    });

    Vue.use(VueGtag, {
        config: {id: __GTAG_ID__}
    }, router);

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
