/*
 * @Author: bowang
 * @Date: 2023-05-17 16:24:07
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-05-28 16:04:56
 */
import { assign, customPost, customGet } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api',
    registerOrLogin: customPost('v2/user/union/registerOrLogin'),
    sendVerificationWithoutCaptcha: customPost('mobile/sendVerificationWithoutCaptcha'),
    fetch: customGet('v2/user/fetch'),
    getMobileNumber: customGet('indosat/getMobileNumber'),
    logout: customPost('user/logout')
};
export default assign(api);
