import { render, staticRenderFns } from "./ec-message-content.vue?vue&type=template&id=28464f9e&scoped=true&"
import script from "./ec-message-content.vue?vue&type=script&lang=js&"
export * from "./ec-message-content.vue?vue&type=script&lang=js&"
import style0 from "./ec-message-content.vue?vue&type=style&index=0&id=28464f9e&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28464f9e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web474js/fintopia-web-indo-ci-prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('28464f9e')) {
      api.createRecord('28464f9e', component.options)
    } else {
      api.reload('28464f9e', component.options)
    }
    module.hot.accept("./ec-message-content.vue?vue&type=template&id=28464f9e&scoped=true&", function () {
      api.rerender('28464f9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/ec-message-content.vue"
export default component.exports