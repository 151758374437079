/*
 * @Author: bowang
 * @Date: 2023-05-25 11:12:23
 * @Last Modified by: bowang
 * @Last Modified time: 2023-05-25 17:15:02
 */
import { assign, customGet } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/messages/:id',
    unreadCount: customGet('unreadCount'),
    readAll: customGet('readAll')
};
export default assign(api);
