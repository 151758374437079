/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-05-30 14:29:29
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/loan',
    getLoanUse: customGet('getLoanUse'),
    getQiniuUploadToken: customGet('getQiniuUploadToken'),
    getIdnStudyProgramList: customGet('education/getIdnStudyProgramList'),
    getIdnCollegeList: customGet('education/getIdnCollegeList'),
    upload: customPost('extraInfo/upload'),
    submitCalcCredits: customPost('submitCalcCredits'),
    listPaymentCredentials: customGet('listPaymentCredentials'),
    me: customGet('me'),
    creditsInfo: customGet('creditsInfo')
};
export default assign(api);
