var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "van-nav-bar",
    _vm._b(
      {
        style: _vm.navBarStyle,
        attrs: { border: false },
        scopedSlots: _vm._u([
          {
            key: "left",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "left-box" },
                  [
                    _vm.needBack
                      ? _c("back", {
                          attrs: { color: _vm.backColor },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.backClick.apply(null, arguments)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowBanner
                      ? [
                          _c("img", {
                            staticClass: "left-banner",
                            attrs: { src: require("./img/left-banner.png") },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "black-text" }, [
                            _vm._v("TKB90=100%"),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "title",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "van-ellipsis", style: _vm.titleStyle },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.isHideTitle ? "" : _vm.title) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "right-box" },
                  [
                    _vm.isShowCustomerService
                      ? _c("ec-customer-service", {
                          staticClass: "customer-service",
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShouNotify
                      ? _c("ec-notify", { staticClass: "notify-box" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isShowOrder
                      ? _c("img", {
                          staticClass: "order-icon",
                          attrs: { src: require("./img/order.svg") },
                          on: { click: _vm.goOrder },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$options.isProd
                      ? _c(
                          "div",
                          {
                            staticClass: "switch-language",
                            on: { click: _vm.switchLanguage },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.displayLocal) +
                                "\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "van-nav-bar",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }