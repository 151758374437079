/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: bowang
 * @Last Modified time: 2023-05-23 14:58:08
 */

import Vue, {VueRouter, createVueRouter} from 'ssr-common/vue';

import routes from './routes';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

export const createRouter = () => createVueRouter({routes});
