<!-- @Author: bowang  -->
<!-- @Date: 2023-05-25 11:09:38  -->
<!-- @Last Modified by:   bowang  -->
<!-- @Last Modified time: 2023-05-25 11:09:38  -->

<template>
    <div class="notify-box">
        <div
            v-show="unreadCount > 0"
            class="red-dot"
        />
        <img
            class="notify-img"
            src="./img/notify.png"
            data-upload="GoCheckMessageBtn"
            @click="onCheckMessage"
        >
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

import MessagesApi from 'easycash-h5/common/resource/messages';

export default {
    name: 'EcNotify',

    data() {
        return {
            timer: null,
            unreadCount: 0
        };
    },

    computed: {
        ...mapGetters('baseData', ['isLogin'])
    },

    watch: {
        isLogin: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.pollMessage();

                    return;
                }

                this.unreadCount = 0;
                this.stop();
            }
        }
    },

    destroyed() {
        this.stop();
    },

    methods: {
        async pollMessage() {
            if (!this.unreadCount) {
                await this.fetchUnreadCount();
            }

            this.timer = setTimeout(() => {
                if (!this.timer) return;
                this.pollMessage();
            }, 100 * 1000);
        },

        async fetchUnreadCount() {
            const {data: {body: {unreadCount}}} = await MessagesApi.unreadCount();
            this.unreadCount = unreadCount;

            return unreadCount;
        },

        onCheckMessage() {
            this.$router.push({name: 'message'});
        },

        stop() {
            this.timer = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.notify-box {
    .red-dot {
        position: absolute;
        right: 0;
        width: 0.05rem;
        height: 0.05rem;
        border-radius: 50%;
        background-color: #ff3b30;
    }

    .notify-img {
        width: 0.16rem;
        height: 0.16rem;
    }
}
</style>
