/*
 * @Author: bowang
 * @Date: 2023-05-23 19:17:00
 * @Last Modified by: bowang
 * @Last Modified time: 2023-05-23 19:35:28
 */
import { assign } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/generalConfig'
};
export default assign(api);
