/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-06-19 16:38:54
 */
import {Toast, Dialog, Overlay, NavBar, Tab, Tabs, List, PullRefresh, Checkbox} from 'vant';

import EcImage from 'ssr-common/vue/component/ec-image';

import EcBtn from './ec-btn';
import EcMessageContent from './ec-message-content';
import EcMessageList from './ec-message-list';
import EcWebviewLoadingCircle from './ec-webview-loading-circle';

Toast.allowMultiple();

export default {
    install(Vue) {
        Vue.use(NavBar);
        Vue.use(Overlay);
        Vue.use(Toast);
        Vue.use(Dialog);
        Vue.use(Toast);
        Vue.use(Tab);
        Vue.use(Tabs);
        Vue.use(List);
        Vue.use(PullRefresh);
        Vue.use(Checkbox);
        Vue.component('ec-webview-loading-circle', EcWebviewLoadingCircle);
        Vue.component('ec-btn', EcBtn);
        Vue.component('ec-message-content', EcMessageContent);
        Vue.component('ec-message-list', EcMessageList);
        Vue.component('ec-image', EcImage);
    }
};
