<!-- @Author: bowang  -->
<!-- @Date: 2023-05-19 17:40:01  -->
<!-- @Last Modified by:   bowang  -->
<!-- @Last Modified time: 2023-05-19 17:40:01  -->

<template>
    <van-tabbar
        v-model="active"
        @change="go"
    >
        <van-tabbar-item>
            <template #icon="{active}">
                <img
                    v-if="active"
                    src="./img/home-active.svg"
                >
                <img
                    v-else
                    src="./img/home.svg"
                >
                <span>{{ getI18n('home') }}</span>
            </template>
        </van-tabbar-item>
        <van-tabbar-item>
            <template #icon="{active}">
                <div class="bills-wrap">
                    <img
                        v-if="active"
                        src="./img/bills-active.svg"
                    >
                    <img
                        v-else
                        src="./img/bills.png"
                    >
                    <div
                        v-if="isLogin && billsTipData && billsTipData.repayingInstalmentCount>0"
                        class="bills-tag"
                        :class="[billsTipData.overdue ? 'bg-red': 'bg-yellow']"
                    >
                        {{ billsTipData.repayingInstalmentCount }}
                    </div>
                </div>
                <span>{{ getI18n('bills') }}</span>
            </template>
        </van-tabbar-item>
        <van-tabbar-item>
            <template #icon="{active}">
                <img
                    v-if="active"
                    src="./img/me-active.svg"
                >
                <img
                    v-else
                    src="./img/me.svg"
                >
                <span>{{ getI18n('me') }}</span>
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import {Tabbar, TabbarItem} from 'vant';
import {mapActions, mapGetters} from 'vuex';

const navBar = ['home', 'bills', 'me'];
export default {
    name: 'EcTabBar',

    components: {
        [Tabbar.name]: Tabbar,
        [TabbarItem.name]: TabbarItem
    },

    data() {
        return {
            active: 0
        };
    },

    computed: {
        ...mapGetters('bills', ['billsTipData']),
        ...mapGetters('baseData', ['isLogin']),
    },

    watch: {
        $route({name}) {
            this.changeTab(name);
            if (!this.isLogin) return;
            this.fetchBillsTip();
        },

        isLogin: {
            immediate: true,
            handler(val) {
                if (!val) return;
                this.fetchBillsTip();
            }
        }
    },

    mounted() {
        this.changeTab(this.$route.name);
    },

    methods: {
        ...mapActions('bills', ['fetchBillsTip']),

        getI18n(str, options) {
            return this.$t(`tabBar.${str}`, options);
        },

        go(index) {
            this.$router.push({name: navBar[index]});
        },

        changeTab(routeName) {
            const curIndex = navBar.findIndex(val => routeName === val);
            if (this.active !== curIndex) {
                this.active = curIndex;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.bills-wrap {
    position: relative;

    .bills-tag {
        position: absolute;
        top: -0.08rem;
        right: -0.08rem;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.02rem;
        font-size: 0.1rem;
        min-width: 0.16rem;
        min-height: 0.16rem;
        color: #fff;
    }

    .bg-red {
        background-color: #ff3b30;
    }

    .bg-yellow {
        background-color: #fcb424;
    }
}
</style>
