/**
 * @Author: zhaoyang
 * @Date: 2023-05-10 18:26:05
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-06-01 15:36:32
 */

import Vuex, {Store} from 'vuex';

import Vue from 'ssr-common/vue';

import baseData from './module/base-data';
import bills from './module/bills';
import channelName from './module/channel-name';
import home from './module/home';

Vue.use(Vuex);

export const createStore = () => new Store({
    modules: {
        baseData,
        home,
        bills,
        channelName
    }
});
