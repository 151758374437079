/*
 * @Author: zhaoyang
 * @Date: 2023-05-11 12:08:52
 * @Last Modified by: bowang
 * @Last Modified time: 2023-05-26 16:34:55
 */
import { BRAND } from 'easycash-h5/common/constant/config';
import clickRipple from './click-ripple';
import generateEvent from './event';
const event = generateEvent(BRAND);
export default {
    install(Vue) {
        Vue.directive('yqg-event', event);
        Vue.directive('click-ripple', clickRipple);
    }
};
