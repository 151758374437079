/*
 * @Author: ruiwang
 * @Date 2023-05-23 15:19:41
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-06-16 15:28:38
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { getHost } from 'easycash-h5/common/constant/config';
const api = {
    getHost,
    urlPrefix: '/api/v5/cashloan/home',
    createOrder: customPost('/api/v2/cashloan/createOrder'),
    productDetail: customGet('/api/cashloan/productDetail'),
    createOrderCheck: customGet('/api/cashloan/createOrderCheck'),
    result: customPost('/api/bizCheck/v2/result'),
    reapply: customPost('/api/v2/loan/reapply')
};
export default assign(api);
