<!-- @Author: zhaoyang -->
<!-- @Date: 2023-05-10 18:26:05 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2023-05-29 23:19:04 -->

<template>
    <div id="app">
        <ec-nav-bar
            v-if="navBarOptions"
            :nav-bar-options="navBarOptions"
        />

        <template v-if="fetched">
            <div class="view-container">
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive" />
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive" />
            </div>

            <div
                v-show="isShowTabBar"
                class="nav-bar-container"
            >
                <ec-tab-bar />
            </div>
        </template>

        <ec-webview-loading-circle v-show="!!reqCount || !fetched" />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import {axios as http} from '@yqg/resource';

import {isServer} from 'ssr-common/constant/config';
import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import yqgCookie from 'ssr-common/util/yqg-cookie';
import Vue from 'ssr-common/vue';
import EventTrackingCache from 'ssr-common/vue/mixin/event-tracking-cache';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import Vconsole from 'ssr-common/vue/mixin/vconsole';

import component from 'easycash-h5/common/component';
import EcNavBar from 'easycash-h5/common/component/ec-nav-bar';
import EcTabBar from 'easycash-h5/common/component/ec-tab-bar';
import {TONGDUN_PARTNER_CODE, TONDUN_APP_NAME, TONDUN_BASE_URL} from 'easycash-h5/common/constant/tongdun-config';
import {
    CODE_NOT_LOGIN
} from 'easycash-h5/common/constant/yqg-status';
import directive from 'easycash-h5/common/directive';
import filter from 'easycash-h5/common/filter';
import FinseasyCache from 'easycash-h5/common/mixin/finseasy-cache';
import parseUa from 'easycash-h5/common/mixin/parse-ua';
import uploadEvent from 'easycash-h5/common/mixin/upload-event';
import plugin from 'easycash-h5/common/plugin';

const {common} = http.defaults.headers;
common['YQG-PLATFORM-SDK-TYPE'] = 'IDN_YQD';
common['build'] = '35313';
common['PlatformType'] = 'WEB';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();

Vue.use(filter);
Vue.use(directive);
Vue.use(component);
Vue.use(plugin);

export default {
    name: 'EasycashH5',

    components: {
        EcNavBar,
        EcTabBar
    },

    mixins: [
        interceptor,
        Vconsole(Vue),
        uploadEvent,
        parseUa(),
        EventTrackingCache,
        FinseasyCache
    ],

    data() {
        return {
            fetched: false,
            selfReqInterceptor: null,
            selfResInterceptor: null
        };
    },

    head() {
        // eslint-disable-next-line
        const keywords = 'pinjol,pinjaman online cepat cair,pinjaman,pinjaman online,easy cash,esy cash,easycash,essy cash,esey cash,akulaku,kredivo,kredit pintar,adakami,pinjaman online cepat cair bayar bulanan,pinjol ojk terbaru cepat cair,rupiah cepat,pt. indonesia fintopia technology,pinjol cepat cair dan mudah,aplikasi pinjaman uang online,aplikasi easycash,pinjam duit,pinjam,pinjam uang,dana,ada kami,uangme,julo,pinjaman online cepat cair terbaru,pinjaman online cepat cair dan mudah,kredit,tunaiku,aplikasi pinjol,apk pinjaman uang online,indodana,adapundi,uku,pinjam yuk,finplus,cairin,ada modal,kta kilat,maucash,kredito,uatas,pinjaman online ojk,apk pinjol,pinjol legal,cicilan';

        const desc = 'Easycash - Pinjaman Uang Online Cepat Cair';

        return {
            meta: [
                {name: 'keywords', content: keywords},
                {name: 'description', content: desc}
            ],
            script: [{
                // tongdun init
                inner: `
                    (function(){
                        window._fmOpt = {
                            partner: '${TONGDUN_PARTNER_CODE}',
                            appName: '${TONDUN_APP_NAME}',
                            success: function(data) {
                                window._fmOpt.blackbox = data;
                            }
                        };
                        var fm = document.createElement('script');
                        fm.type = 'text/javascript'; fm.async = true;
                        fm.src = '${TONDUN_BASE_URL}' + '?t=' + (new Date().getTime()/3600000).toFixed(0);
                        var s = document.getElementsByTagName('script')[0];
                        s.parentNode.insertBefore(fm, s);
                    })();
                `
            }]
        };
    },

    watch: {
        error(nVal) {
            if (nVal?.detail) {
                this.$toast.fail(nVal?.detail);
            }

            if (nVal?.code === 40000110) {
                const {channelName} = this.$route.query;
                const {mobileNumber} = this.userInfo ?? {};

                this.timer = setTimeout(() => {
                    this.$router.push({
                        name: 'RegisterLoginStatus',
                        query: {
                            noReturn: true,
                            channelName,
                            mobileNumber
                        }
                    });
                }, 2000);
            }
        }
    },

    computed: {
        ...mapGetters('baseData', ['userInfo']),
        ...mapGetters('home', ['error']),

        isHalf() {
            return this.$route.name?.includes?.('Half');
        },

        navBarOptions() {
            return this.$route.meta.navBar;
        },

        isShowTabBar() {
            return this.$route.meta.showTabBar;
        },

        gaid() {
            let {$route: {query: {idValue: gaid}}} = this;

            if (!gaid && !isServer()) {
                gaid = this.getFinseasy('gaid');
            }

            return gaid;
        },

        dataCallbackChannel() {
            let {$route: {query: {dataCallbackChannel}}} = this;

            if (!dataCallbackChannel && !isServer()) {
                dataCallbackChannel = this.getFinseasy('dataCallbackChannel');
            }

            return dataCallbackChannel;
        }
    },

    created() {
        Vue.prototype.$app = this;
        const {$route: {name}} = this;
        if ([
            'registerLogin',
            'registerLoginHalf',
            'appGuideHalf',
            'RegisterLoginStatus',
            'protocol',
            'deleteUserGuide'
        ].includes(name)) {
            this.fetched = true;
        }
    },

    beforeMount() {
        this.initUnloginStatus();
    },

    mounted() {
        this.initFineasy();
        this.initUserInfo();
    },

    methods: {
        ...mapActions('baseData', ['getUserInfo', 'clearUserInfo']),
        ...mapActions('home', ['clearSignTimer', 'clearTimer']),
        ...mapActions(['fetchHome']),

        initFineasy() {
            // gaid: oppo 合作包finseasy提供的设备标识
            const {$route: {query: {idValue: gaid, dataCallbackChannel}}} = this;
            if (gaid) {
                this.saveFinseasy('gaid', gaid);
            }

            if (dataCallbackChannel) {
                this.saveFinseasy('dataCallbackChannel', dataCallbackChannel);
            }
        },

        confirm(options) {
            return this.$dialog.confirm({
                className: 'dialog-confirm',
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                ...options
            });
        },

        initUnloginStatus() {
            if (this.selfReqInterceptor) return;

            this.selfReqInterceptor = http.interceptors.request.use(
                config => {
                    config.headers['YQG-PLATFORM-LANGUAGE'] = this.$i18n?.locale;
                    const {gaid, dataCallbackChannel} = this;
                    if (gaid) {
                        config.headers['YQG-PLATFORM-GAID'] = gaid;
                    }

                    if (dataCallbackChannel) {
                        config.headers['DATA-CALLBACK-CHANNEL'] = dataCallbackChannel;
                    }

                    // 同盾blackbox
                    if (!isServer()) {
                        const {blackbox} = window._fmOpt || {}; // eslint-disable-line no-underscore-dangle
                        if (blackbox) {
                            config.headers['blackbox'] = blackbox;
                        }
                    }

                    return config;
                },
                err => err
            );
            this.selfResInterceptor = http.interceptors.response.use(
                req => req,
                error => {
                    const {data: {status: {code} = {}} = {}} = error;
                    if (code === CODE_NOT_LOGIN) {
                        this.clearUserInfo();
                        yqgCookie.clearUserToken();
                        const RouteName = this.$route.name;

                        if (RouteName?.includes?.('Half')) {
                            if (RouteName !== 'registerLoginHalf') {
                                this.$router.replace({name: 'registerLoginHalf'});
                            }
                        } else {
                            if (RouteName !== 'registerLogin') {
                                this.$router.replace({name: 'registerLogin'});
                            }
                        }
                    }

                    return Promise.reject(error);
                }
            );
        },

        clearSelfClientInterceptors() {
            http.interceptors.request.eject(this.selfReqInterceptor);
            http.interceptors.response.eject(this.selfResInterceptor);
        },

        async initUserInfo() {
            if (this.fetched) return;

            try {
                const userToken = yqgCookie.getUserToken();
                const loginStatus = !!localStorage.getItem('loginStatus');
                if (userToken || loginStatus) {
                    await this.getUserInfo();
                } else {
                    this.fetchHome();
                }
            } catch (err) {
                // ignore
            }

            this.fetched = true;
        }
    },

    beforeDestroy() {
        this.clearSelfClientInterceptors();
        this.clearTimer();
        this.clearSignTimer();
        clearTimeout(this.timer);
    }
};

</script>

<style lang="scss">
@import "../common/style/index.scss";

#app {
    overflow: hidden;
    background-color: $mainBg;
    height: 100%;
    width: 100%;
    max-width: $maxWidth;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .view-container {
        flex: 1;
        overflow: auto;
    }

    .nav-bar-container {
        height: 0.74rem;
    }
}
</style>
