/*
 * @Author: zhaoyang
 * @Date: 2023-06-14 17:42:56
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-06-15 13:24:30
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Bills from 'easycash-h5/common/resource/bills';
export default {
    namespaced: true,
    state: () => ({
        billsTipData: null
    }),
    getters: {
        billsTipData(state) {
            return state.billsTipData;
        }
    },
    mutations: {
        SetBillsTip(state, data) {
            state.billsTipData = data;
        }
    },
    actions: {
        fetchBillsTip({ commit }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data: { body } } = yield Bills.billingOrderCountTip();
                commit('SetBillsTip', body !== null && body !== void 0 ? body : {});
            });
        }
    }
};
