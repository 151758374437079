/*
 * @Author: zhaoyang
 * @Date: 2024-01-25 14:05:36
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-25 14:13:50
 */
export function saveLocalStorage(key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    }
    catch (e) {
        // ignore saveLocalStorage error
    }
}
export function getLocalStorage(key) {
    try {
        return JSON.parse(localStorage.getItem(key));
    }
    catch (e) {
        // ignore getEventParam error
    }
}
export function removeLocalStorage(key) {
    localStorage.removeItem(key);
}
